<template>
  <div id="clothSheetDetail" class="clothSheetDetail">
    <div class="title">坯布生产查询</div>
    <div class="clothNumber">
      <div class="left">
        <div class="filterItem">
          <div style="text-align: right; margin-right: 10px">坯布指示单号</div>
          <Input
            placeholder="请输入搜索内容"
            v-model="ztgcpoiWeaveDetail"
            style="width: 150px"
            @keyup.enter.native="changeMsg"
          />
        </div>
      </div>
      <div class="right">{{ nowZtgcpoiWeaveDetail }}</div>
    </div>
    <div class="message">
      <div class="left">
        <div class="title">基础信息</div>
        <div class="content">
          <table border="0" cellspacing="0">
            <tr>
              <th><span>客户名称</span>：</th>
              <td>{{ ztGreyClothPlanOrder.ztgcpoCustomer }}</td>
              <th><span>匹数(匹)</span>：</th>
              <td>{{ ztGreyClothPlanOrder.ztgcpoiClothNum }}</td>
            </tr>
            <tr>
              <th><span>款式</span>：</th>
              <td>{{ ztGreyClothPlanOrder.ztgcpoStyleNumber }}</td>
              <th><span>数量(KG)</span>：</th>
              <td>{{ ztGreyClothPlanOrder.ztgcpoiPlanNum | numFormat }}</td>
            </tr>
            <tr>
              <th><span>颜色</span>：</th>
              <td>{{ ztGreyClothPlanOrder.ztgcpoiFabricColor }}</td>
              <th><span>交货日期</span>：</th>
              <td>{{ ztGreyClothPlanOrder.ztgcpoDeliveryDate | timeFormat }}</td>
            </tr>
            <tr>
              <th><span>货号</span>：</th>
              <td>{{ ztGreyClothPlanOrder.greyClothItemNo }}</td>
              <th><span>合同号</span>：</th>
              <td>{{ ztGreyClothPlanOrder.ztgcpoContractNo }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="right">
        <div class="title">生产计划</div>
        <div class="content">
          <div class="table">
            <table>
              <tr>
                <th><span>计划日期</span>：</th>
                <td>{{ ztGreyClothPlanOrder.ztgcpoPlanDate | timeFormat }}</td>
              </tr>
              <tr>
                <th><span>订单交期</span>：</th>
                <td>{{ ztGreyClothPlanOrder.ztgcpoOrderDeliveryDate | timeFormat }}</td>
              </tr>
              <tr>
                <th><span>排产机台</span>：</th>
                <td>共{{ machineSum }}台</td>
              </tr>
              <tr>
                <th></th>
                <td>({{ machineNum }})</td>
              </tr>
            </table>
          </div>
          <div class="image">
            <img
              src="../../assets/images/productIn.png"
              v-if="
                ztGreyClothPlanOrder.ztgcpoFactoryName === '绍兴纵通纺织有限公司' ||
                  ztGreyClothPlanOrder.ztgcpoFactoryName === ''
              "
            />
            <img src="../../assets/images/productOut.png" v-else />
          </div>
        </div>
      </div>
    </div>
    <div class="productDetail">
      <div class="title">生产详情</div>
      <div class="content">
        <div class="item">
          <table border="0" cellspacing="0">
            <tr>
              <th>
                {{ Number(remainingDays) >= 0 ? "剩余" : "延迟" }}
                <!-- <Tooltip placement="top" theme="light">
                  <img src="../../assets/images/icon-help.png"/>
                  <div slot="content">
                    <p><span class="circle blue"></span>剩余天数</p>
                    <p><span class="circle red"></span>延迟天数</p>
                </div>
                </Tooltip> -->
              </th>
              <th>织造要求数量(KG)</th>
              <th>织造要求匹数(匹)</th>
            </tr>
            <tr>
              <td>
                <span
                  :style="
                    Number(remainingDays) > 0
                      ? 'color:#256DE6;font-size:25px;'
                      : 'color:#F94040;font-size:25px;'
                  "
                  >{{ Math.abs(remainingDays) }}</span
                >天
              </td>
              <td>
                {{ ztgcpoTotalNum | numFormat }}
              </td>
              <td>
                {{ ztgcpoClothQuantity }}
              </td>
            </tr>
          </table>
        </div>
        <div class="item">
          <table border="0" cellspacing="0">
            <tr>
              <th>领料数量(KG)</th>
              <th>检验进仓匹数(匹)</th>
              <th>检验进仓数量(KG)</th>
            </tr>
            <tr>
              <td>
                {{ pickMaterialsNum | numFormat }}
              </td>
              <td>
                {{ ztgctClothQuantity }}
              </td>
              <td>
                {{ ztgctWeight | numFormat }}
              </td>
            </tr>
          </table>
        </div>
        <div class="item">
          <table border="0" cellspacing="0">
            <tr>
              <th>瑕疵点数(个)</th>
            </tr>
            <tr>
              <td>
                {{ ztgctFlawNum }}
              </td>
            </tr>
          </table>
        </div>
        <div class="item">
          <table border="0" cellspacing="0">
            <tr>
              <th>完成率</th>
            </tr>
            <tr>
              <td>
                {{ finishRate }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="table">
        <div class="left">
          <Table
            :columns="columns1"
            :data="workPersonList"
            border
            show-summary
            :summary-method="handleSummary1"
            max-height="200"
          ></Table>
        </div>
        <div class="right">
          <Table
            :columns="columns2"
            :data="machineList"
            border
            show-summary
            :summary-method="handleSummary2"
            max-height="200"
          ></Table>
        </div>
      </div>
    </div>
    <div class="clothProduct">
      <div class="title">坯布产量跟踪</div>
      <div class="content" id="outputChart" style="width: 100%; height: 250px"></div>
    </div>
    <div class="material">
      <div class="title">原材料领料</div>
      <div class="content">
        <Table
          :columns="columns3"
          :data="ztMaterialOutList"
          border
          show-summary
          max-height="840"
        ></Table>
      </div>
    </div>
    <div class="entry">
      <div class="title">坯布进仓</div>
      <div class="content">
        <Table
          :columns="columns4"
          :data="ztGreyClothEnter.list"
          border
          show-summary
          :summary-method="handleSummary4"
          max-height="840"
        ></Table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      machineSum: 0, // 总共机台数
      machineNum: "无", // 机台号展示

      ztgcpoiWeaveDetail: this.$route.query.ztgcpoiWeaveDetail, // 坯布指示单号输入框
      nowZtgcpoiWeaveDetail: "", // 当前页面展示的坯布指示单号

      remainingDays: 0, // 剩余/延迟（天数，当前日期-订单交期）
      finishRate: "0%", // 完成率
      ztgcpoTotalNum: 0, // 织造要求数量
      ztgcpoClothQuantity: 0, // 织造要求匹数
      pickMaterialsNum: 0, // 领料数量

      ztgctClothQuantity: 0, // 检验进仓匹数
      ztgctWeight: 0, // 检验进仓数量
      ztgctFlawNum: 0, // 瑕疵点数

      machineList: [],
      workPersonList: [],
      ztGreyClothPlanOrder: {}, // 坯布指示单主要信息
      ztMaterialOutList: [], // 原材料领料的表格
      ztGreyClothEnter: [], // 坯布进仓相关

      xAxis: [],
      series: [],

      columns1: [
        {
          title: "序号",
          type: "index",
          width: 65,
          align: "center"
        },
        {
          title: "挡车工姓名",
          key: "ztgctWorkPerson"
        },
        {
          title: "进仓匹数",
          key: "ztgctClothQuantity"
        },
        {
          title: "数量(KG)",
          key: "ztgctWeight"
        }
      ],
      columns2: [
        {
          title: "序号",
          type: "index",
          width: 65,
          align: "center"
        },
        {
          title: "机台号",
          key: "ztgctDeviceNumber"
        },
        {
          title: "生产匹数",
          key: "ztgctClothQuantity"
        },
        {
          title: "数量(KG)",
          key: "ztgctWeight"
        }
      ],
      columns3: [
        {
          title: "序号",
          type: "index",
          width: 65,
          align: "center"
        },
        {
          title: "原材料出仓单号",
          key: "ztmoOrderNumber"
        },
        {
          title: "原材料名称",
          key: "ztmoiSpecification"
        },
        {
          title: "担当",
          key: "ztmoTakeOn"
        },
        {
          title: "织造要求数量(KG)",
          key: "ztmoNumberClaims"
        },
        {
          title: "领料件数",
          key: "ztmoClothQuantity"
        },
        {
          title: "领料数量(KG)",
          key: "ztmoTotalNum"
        },
        {
          title: "领料日期",
          key: "ztmoDocDate"
        }
      ],
      columns4: [
        {
          title: "序号",
          type: "index",
          width: 65,
          align: "center"
        },
        {
          title: "货号",
          key: "greyClothItemNo"
        },
        {
          title: "品名",
          key: "ztgceiPurchaseName"
        },
        {
          title: "颜色",
          key: "ztgceiColor"
        },
        {
          title: "花型",
          key: "ztgceiDesignType"
        },
        {
          title: "匹数",
          key: "ztgceiClothNum"
        },
        {
          title: "数量(KG)",
          key: "ztgceiNum"
        },
        {
          title: "存放位置",
          key: "ztgceiLocation"
        },
        {
          title: "品质状态",
          key: "ztgceiQualityStatus"
        }
      ]
    };
  },
  filters: {
    timeFormat(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    numFormat(value) {
      return Number(value).toFixed(2);
    }
  },
  mounted() {
    this.getMsg(this.getEchart);
  },
  methods: {
    handleSummary1({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "合计"
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value)) && key !== "ztgctWorkPerson") {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[key] = {
            key,
            value: Number(v).toFixed(2)
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });

      return sums;
    },
    // 累计自定义的函数
    handleSummary2({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "合计"
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value)) && key !== "ztgctDeviceNumber") {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[key] = {
            key,
            value: Number(v).toFixed(2)
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });

      return sums;
    },
    // 累计自定义的函数
    handleSummary4({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "合计"
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (
          !values.every(value => isNaN(value)) &&
          key !== "ztgceiPurchaseName" &&
          key !== "ztgceiColor"
        ) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[key] = {
            key,
            value: Number(v).toFixed(2)
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });

      return sums;
    },
    // 回车切换信息
    changeMsg() {
      this.getMsg(this.getEchart);
    },
    getEchart() {
      const that = this;
      var chartDom = document.getElementById("outputChart");
      var myChart = this.echarts.init(chartDom);
      var option;

      option = {
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function(params) {
            return (
              params[1].name +
              "<br/>" +
              params[0].seriesName +
              " : " +
              params[0].value +
              "<br/>" +
              params[1].seriesName +
              " : " +
              params[1].value +
              "<br/>" +
              params[2].seriesName +
              " : " +
              params[2].value
            );
          }
        },
        xAxis: {
          data: that.xAxis
        },
        yAxis: {
          type: "value",
          name: "单位(KG)"
        },
        series: that.series
      };

      option && myChart.setOption(option);
    },
    getMsg(callback) {
      if (this.ztgcpoiWeaveDetail === "") {
        this.$Message.warning("请输入坯布指示单号搜索");
        return;
      }
      this.axios({
        url: "/dtsum/zongtong/order/planController/clothSheetSearchDetail",
        method: "get",
        params: {
          weaveDetail: this.ztgcpoiWeaveDetail
        }
      })
        .then(res => {
          if (res.data.success === 1) {
            const body = res.data.body;
            this.nowZtgcpoiWeaveDetail = body.ztGreyClothPlanOrder.ztgcpoiWeaveDetail;
            this.remainingDays = body.remainingDays;
            this.finishRate = body.finishRate;
            this.ztgcpoTotalNum = body.ztgcpoTotalNum;
            this.ztgcpoClothQuantity = body.ztgcpoClothQuantity;
            this.pickMaterialsNum = body.pickMaterialsNum;
            this.ztgctClothQuantity = body.ztgctClothQuantity;
            this.ztgctWeight = body.ztgctWeight;
            this.ztgctFlawNum = body.ztgctFlawNum;

            this.ztGreyClothPlanOrder = JSON.parse(JSON.stringify(body.ztGreyClothPlanOrder));
            this.workPersonList = JSON.parse(JSON.stringify(body.ztGreyClothTest.workPersonList));
            this.machineList = JSON.parse(JSON.stringify(body.ztGreyClothTest.machineList));
            this.machineSum = this.machineList.length ? this.machineList.length : 0;
            if (this.machineList.length) {
              this.machineNum = "";
              this.machineList.map(item => {
                if (item.ztgctDeviceNumber !== "") {
                  this.machineNum = this.machineNum + item.ztgctDeviceNumber + "、";
                }
              });
              this.machineNum = this.machineNum.slice(0, this.machineNum.length - 1);
            } else {
              this.machineNum = "无";
            }
            this.ztGreyClothTest = JSON.parse(JSON.stringify(body.ztGreyClothTest));
            this.ztMaterialOutList = body.ztMaterialOut.list;
            this.ztGreyClothEnter = JSON.parse(JSON.stringify(body.ztGreyClothEnter));
            this.xAxis = body.ztGreyClothEnter.xAxis;
            this.series = body.ztGreyClothEnter.series;
            this.series.forEach((item, index) => {
              if (item.name === "累计") {
                this.series[index].itemStyle = {
                  barBorderColor: "rgba(0,0,0,0)",
                  color: "rgba(0,0,0,0)"
                };
                this.series[index].stack = "总量";
              }
              if (item.name === "单日量产") {
                this.series[index].stack = "总量";
                this.series[index].label = {
                  show: true,
                  position: "inside",
                  formatter: function(params) {
                    if (params.value === 0 || params.value === "0") {
                      return "";
                    } else {
                      return params.value;
                    }
                  }
                };
              }
              if (item.name === "单日匹数") {
                this.series[index].type = "line";
                this.series[index].symbolSize = 0;
                this.series[index].showSymbol = false;
                this.series[index].lineStyle = {
                  width: 0, // 线宽是0
                  color: "rgba(0, 0, 0, 0)" // 线的颜色是透明的
                };
              }
            });
            callback();
          } else {
            this.$Message.warning("查询失败！");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#clothSheetDetail {
  .title {
    text-align: center;
    font-size: 26px;
    color: #333;
    font-weight: $--fontweight-medium;
  }
  .clothNumber {
    display: flex;
    justify-content: space-between;
    font-size: $--fontsize-title;
    .left {
      font-size: $--fontsize-small;
      display: inline-flex;
      .filterItem {
        display: flex;
      }
    }
    .right {
      font-size: $--fontsize-title;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333;
    }
  }
  .message {
    background: #f5f5f5;
    padding: 20px 30px 10px 30px;
    margin-top: 20px;
    display: flex;
    .left {
      width: 50%;
      .content {
        margin: 10px 0;
        display: flex;
        table {
          width: 100%;
          tr {
            width: 100%;
            height: 30px;
            line-height: 30px;
            th {
              width: 15%;
              color: rgba(51, 51, 51, 0.65);
              span {
                display: inline-block;
                width: 50%;
                text-align: justify;
                text-align-last: justify;
              }
            }
            td {
              width: 30%;
              color: #333;
              font-weight: bold;
            }
          }
        }
      }
    }
    .right {
      width: 50%;
      padding-left: 50px;
      border-left: 1px solid #e9e5e5;
      .content {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        .table {
          width: 70%;
          table {
            width: 100%;
            tr {
              width: 100%;
              height: 30px;
              line-height: 30px;
              th {
                text-align: start;
                width: 11%;
                color: rgba(51, 51, 51, 0.65);
                span {
                  display: inline-block;
                  width: 50%;
                  text-align: justify;
                  text-align-last: justify;
                }
              }
              td {
                width: 30%;
                color: #333;
                font-weight: bold;
              }
            }
          }
        }
        .image {
          width: 120px;
          height: 120px;
          img {
            width: 100%;
            height: 100;
            object-fit: cover;
          }
        }
      }
    }
    .title {
      text-align: start;
      font-size: 20px;
    }
  }
  .productDetail {
    margin-top: 20px;
    .title {
      text-align: start;
      font-size: $--fontsize-bigtitle;
    }
    .content {
      margin-top: 20px;
      background-color: #f5f5f5;
      display: flex;
      width: 100%;
      padding: 20px 0;
      .item {
        img {
          position: absolute;
          top: -15px;
          left: 0;
          transform: translateY(2px);
        }
        .circle {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 5px;
          transform: translateY(-2px);
          &.blue {
            background: #256de6;
          }
          &.red {
            background: #f94040;
          }
        }
        &:nth-of-type(1) {
          width: 37.5%;
          border-right: 1px solid #e2e0e0;
          table {
            width: 100%;
            tr {
              width: 100%;
              height: 30px;
              line-height: 30px;
              th {
                position: relative;
                text-align: center;
                width: 33.33%;
                font-size: $--fontsize-small;
              }
              td {
                text-align: center;
                width: 33.33%;
                font-size: $--fontsize-title;
                font-weight: $--fontweight-medium;
              }
            }
          }
        }
        &:nth-of-type(2) {
          width: 37.5%;
          border-right: 1px solid #e2e0e0;
          table {
            width: 100%;
            tr {
              width: 100%;
              height: 30px;
              line-height: 30px;
              th {
                text-align: center;
                width: 33.33%;
                font-size: $--fontsize-small;
              }
              td {
                text-align: center;
                width: 33.33%;
                font-size: $--fontsize-title;
                font-weight: $--fontweight-medium;
              }
            }
          }
        }
        &:nth-of-type(3) {
          width: 12.5%;
          border-right: 1px solid #e2e0e0;
          color: #f94040;
          table {
            width: 100%;
            tr {
              width: 100%;
              height: 30px;
              line-height: 30px;
              th {
                text-align: center;
                width: 100%;
                font-size: $--fontsize-small;
              }
              td {
                text-align: center;
                width: 100%;
                font-size: $--fontsize-title;
                font-weight: $--fontweight-medium;
              }
            }
          }
        }
        &:nth-of-type(4) {
          width: 12.5%;
          color: #256de6;
          table {
            width: 100%;
            tr {
              width: 100%;
              height: 30px;
              line-height: 30px;
              th {
                text-align: center;
                width: 100%;
                font-size: $--fontsize-small;
              }
              td {
                text-align: center;
                width: 100%;
                font-size: $--fontsize-title;
                font-weight: $--fontweight-medium;
              }
            }
          }
        }
      }
    }
    .table {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .left {
        width: calc(50% - 20px);
      }
      .right {
        width: calc(50% - 20px);
      }
    }
  }
  .clothProduct {
    margin-top: 20px;
    .title {
      text-align: start;
      font-size: $--fontsize-bigtitle;
    }
    .content {
      margin-top: 20px;
    }
  }
  .material {
    margin-top: 20px;
    .title {
      text-align: start;
      font-size: $--fontsize-bigtitle;
    }
    .content {
      margin-top: 20px;
    }
  }
  .entry {
    margin-top: 20px;
    .title {
      text-align: start;
      font-size: $--fontsize-bigtitle;
    }
    .content {
      margin-top: 20px;
    }
  }
}
</style>
<style lang="scss">
#clothSheetDetail {
  .ivu-select-selection {
    height: 24px !important;
  }
  .ivu-select-placeholder {
    line-height: 24px !important;
    height: 24px !important;
  }
  .ivu-select-selected-value {
    line-height: 24px !important;
    height: 24px !important;
  }
  .ivu-input {
    height: 24px !important;
  }
  .ivu-input-suffix i {
    line-height: 24px !important;
  }
  .ivu-icon-ios-time-outline {
    line-height: 24px !important;
  }
  .ivu-checkbox-default {
    display: inline-block;
  }
}
</style>
